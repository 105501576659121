table {
	border-collapse: collapse;
}

table td,
table th {
	text-align: left;
	vertical-align: top;
	padding: 12px 20px;
}
table thead th {
	font-weight: 600;
	font-size: 20px;
	padding: 12px 15px;
	border-bottom: 1px solid gray;
	border-top: 1px solid gray;
}
table tbody td {
	color: #303030;
}

table tbody tr {
	border-bottom: 1px solid gray;
}

aside {
	width: 50%;
	padding: 8px 24px 16px 24px;
	/* border-radius: 8px; */
}
.heading {
	font-size: 32px;
	font-weight: 400;
}

.leftOption {
	border-radius: 16px 0 0 16px;
}
.rightOption {
	/* border-left: 2px solid slategray; */
	border-radius: 0 16px 16px 0;
}

.serviceOptions p,
.serviceOptions li {
	font-family: "Be Vietnam Pro", sans-serif;
	font-weight: 200;
}
