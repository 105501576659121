.bookCar li {
	background: #bad5eb;
	margin-top: 16px;
	margin-bottom: 0px;
	max-width: 22rem;
	padding: 8px 12px;
	font-size: 1.2rem;
	border-radius: 8px;
	cursor: pointer;
}
.bookCar li:first-child {
	margin-top: 32px;
}
.bookCar li:last-child {
	margin-bottom: 40px;
}
.bookCar li:first-child {
	margin-top: 32px;
}
.bookCar li:hover {
	background-color: #9bc1e0;
	transition: 0.2s;
}
.bookCar li.active {
	background-color: var(--secondary-color);
	color: white;
	transition: 0.2s;
}

.slider-img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.touristPlaceOption {
	height: 100%;
	border: 1px solid rgba(0, 0, 0, 0.308);
	overflow-y: auto;
	overflow-x: hidden;
}
.touristPlaceOption li {
	padding: 6px 4px;
	cursor: pointer;
	width: 180px;
	border-bottom: 1px solid rgb(185, 185, 185);
}
/* .touristPlaceOption li:first-child{
	margin-top: 16px;
} */
.touristPlaceOption li:hover {
	background: rgba(171, 171, 171, 0.166);
}
.touristPlaceOption li.active {
	background: rgba(110, 110, 110, 0.659);
}
.swiper-button-prev:after,
.swiper-button-next:after {
	font-size: 10px !important;
	color: rgb(255, 255, 255);
	font-weight: 700;
	background-color: #00000038;
	padding: 10px;
	border-radius: 100px;
}

.slider-img {
	/* height: 90%; */
	object-fit: cover;
}
.imageName {
	background: #00000078;
	backdrop-filter: blur(2px);
	color: rgb(255, 255, 255);
	font-weight: 500;
}


