.bg-Image {
	background-image: url(../../../public/assets/images/owner-img.jpeg);
	height: 370px;
	background-size: cover;
	background-repeat: repeat;
	width: 100%;
	font-size: 48px;
	font-weight: 700;
	color: rgb(255, 255, 255);
}
.ExploreCab-section img {
	object-position: center top;
}
.ExploreCab-section {
	height: 450px;
}
.CabDetails {
	background-color: rgb(255, 255, 255);
	box-shadow: 0px 3px 6px 0px rgba(184, 184, 184, 0.589);
	-webkit-box-shadow: 0px 3px 6px 0px rgba(154, 154, 154, 0.489);
	-moz-box-shadow: 0px 3px 6px 0px rgba(142, 142, 142, 0.57);

	position: relative;
	overflow: visible;
}

.cabOption li {
	padding: 10px 22px;
	border: 1px solid #919191;
	border-right: hidden;
	cursor: pointer;
	font-size: 14px;
	font-weight: 500;
	color: #4d4d4d;
}
.cabOption li:hover {
	background-color: #9a9a9a2f;
	transition: 0.2s;
}
.cabOption li.active {
	background-color: var(--primary-color);
	color: white;
	transition: 0.2s;
}

.cabOption li:first-child {
	border-radius: 6px 0 0 6px;
}
.cabOption li:last-child {
	border-right: 1px solid #919191;
	border-radius: 0 6px 6px 0;
}
form label {
	font-weight: 500;
}

.cabDetailsInput {
	outline: none;
	background-color: rgba(255, 255, 255, 0.71);
	width: 100%;
	padding: 5px 4px;
	border-bottom: 1px solid gray;
	margin-top: 8px;
}
.cabDetailsInput::placeholder {
	font-size: 14px;
	font-weight: 300;
}

.cabDetailsInput:focus {
	border-bottom: 2px solid var(--primary-color);
}

.search-button {
	background-color: var(--primary-color);
	font-weight: 500;
	font-size: 20px;
	color: white !important;
	padding: 6px 50px;
	transition: 0.3s ease-out;
	text-align: center;
	border-radius: 6px;
	position: absolute;
	bottom: -20px;
	margin: auto;
}

.search-button:hover {
	background-color: rgb(29, 28, 28);
	text-decoration: none;
}

.tripOption {
	outline: none;
	width: 100%;
	margin-top: 14px;
	padding-bottom: 4px;
	border-bottom: 1px solid gray;
}

.tripOption:focus {
	border-bottom: 2px solid var(--primary-color);
}

@media (max-width: 1280px) {
	/* .CabDetails {
		margin: 0 28px 0 28px ;
	} */
}

@media (max-width: 768px) {
	
}
