.TermsAndConditions {
	/* font-family: "Be Vietnam Pro", sans-serif; */
	font-weight: 400;
	font-size: 17px;
	color: rgb(26, 26, 26);
	letter-spacing: 0.01rem;
}

.terms-container h2 {
	margin-bottom: 10px;
}

.terms-container p {
	margin-bottom: 10px;
}

.terms-container ul {
	list-style-type: disc;
	padding-left: 20px;
}

.terms-container ul ul {
	list-style-type: circle;
}

.terms-container li {
	margin-bottom: 10px;
}
