.inputField {
	margin-top: 12px;
}

.inputField input:focus,
.inputField textarea:focus {
	border: 1px solid var(--secondary-color);
}
.inputField input,
.inputField textarea {
	background: #f6f6f6;
	margin-top: 2px;
	width: 100%;
	padding: 6px 8px;
	outline: none;
	border: 1px solid rgb(183, 183, 183);
	font-family: "Be Vietnam Pro", sans-serif;
	font-weight: 300;
	border-radius: 4px;
}

.inputField p {
	font-size: 18px;
}

.details p{
	font-size: 17px;
	font-weight: 500;
}