.services p {
	color: white;
	font-family: "Be Vietnam Pro", sans-serif;
	font-weight: 200;
	font-size: 14px;
	letter-spacing: 0.02rem;
	margin-bottom: 4px;
}
.underline-animation,
.footerLink p {
	font-family: "Be Vietnam Pro", sans-serif;
	font-weight: 200;
	font-size: 14px;
	letter-spacing: 0.02rem;
	position: relative;
	display: inline-block;
	cursor: pointer;
}
.footerLink p {
	color: white;
}
.underline-animation::after,
.footerLink p::after {
	content: "";
	position: absolute;
	left: 0;
	bottom: -1px;
	width: 100%;
	height: 2px;
	background-color: #a3a5f9;
	transform: scaleX(0);
	transform-origin: bottom right;
	transition: transform 0.3s ease-out;
}

.underline-animation:hover::after,
.footerLink p:hover::after {
	transform: scaleX(1);
	transform-origin: bottom left;
}
