@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	color: #252525;
}

:root {
	--primary-color: #0377d8;
	--primary-color-dark: #0062b1;
	--secondary-color: #e84f03;
	--primary-color-darken: #0a222a;
	--primary-text-color: #222222;
	--padding-inline-section: 20px;
	--loader-bg-color: #cccccc;
}
h1 {
	font-size: 2.125rem;
	font-weight: bold;
}

h2 {
	font-size: 1.875rem;
	font-weight: bold;
}

h3 {
	font-size: 1.5rem;
	font-weight: bold;
}

a {
	text-decoration: none;
	display: inline-block;
}

ul {
	list-style: none;
}

/* utility classes */
.textColor {
	color: #252525;
}
.small-bold-text {
	font-size: 1rem;
	font-weight: 700;
}

.container {
	max-width: 1200px;
	margin-inline: auto;
	overflow: hidden;
}

.hover-link {
	color: var(--primary-color-dark);
	transition: 0.2s ease-out;
}

.hover-link:hover {
	color: var(--secondary-color);
}

.primary-button {
	background-color: var(--primary-color);
	font-weight: 500;
	font-size: 20px;
	color: white !important;
	padding: 6px 12px;
	transition: 0.3s ease-out;
	text-align: center;
	border-radius: 6px;
}

.primary-button:hover {
	background-color: var(--secondary-color);
}

.secondary-button {
	background-color: var(--secondary-color);
	color: white !important;
	padding: 10px 24px;
	font-weight: 700;
	transition: 0.2s ease-out;
}

.secondary-button:hover {
	background-color: var(--primary-color);
}

.required {
	color: red;
	font-size: 16px;
}
