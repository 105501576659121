.accordion-item {
	max-width: 100%;
}
.accordion-question {
	display: flex;
	cursor: pointer;
	width: 100%;
	margin: 0 10px 0 15px;
	transition: max-height 0.3s ease, opacity 0.3s ease;
}
.question {
	width: 100%;
	font-size: 20px;
	font-weight: 400;
	border-bottom: 1px solid rgba(128, 128, 128, 0.564);
	padding: 4px 10px;
	transition: 0.4s;
}

.icon {
	display: flex;
	align-items: center;
	position: relative;
	right: 40px;
	top: 16px;
	height: 10px;
	transition: 0.5s;
	/* transition: transform 0.3s ease; */
}
.accordion-answer {
	padding: 10px 28px 28px 32px;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid gray;
	background-color: #f3f3f3bd;
	margin: 0 15px 0 15px;
}
.active {
	background-color: #9393938e;
	transition: 0.2s;
	background-color: #e84f03;
	color: #fff;
}

.activeIcon {
	transform: rotate(90deg);
	color: white;
}
.activeIcon .icons path {
	color: white;
}

/* .accordion-item {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
}

.accordion-question {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.accordion-answer {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: max-height 0.3s ease, opacity 0.3s ease;
}

.accordion-answer.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.accordion-item.active .accordion-answer {
    max-height: 500px;
    opacity: 1;
}

.question {
    font-weight: bold;
    font-size: 1.2em;
}

.icon {
    transition: transform 0.3s ease;
}

.activeIcon {
    transform: rotate(90deg);
} */
