@media (max-width: 1280px) {
	.Nav-section {
		padding: 0 28px;
	}
}
@media (max-width: 640px) {
	.Nav-section {
		padding: 0 8px;
	}
	.logo-icon {
		width: 35px;
		height: 32px;
	}
	.logo-name {
		width: 120px;
	}
	.heading-number {
		background-color: transparent;
		color: rgb(30, 30, 30);
	}
	.heading-number a {
		letter-spacing: 0em;
		border: 2px solid var(--primary-color);
		border-radius: 50px;
		padding: 2px 8px;
	}
}
